import React, { useState } from "react";
import "./assets/css/base/base.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Home from "./pages/homepage/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import SnackbarContext from "./contexts/Snackbar";
import ProtectedRoute from "./components/dashboard/ProtectedRoute";
import Login from "./pages/dashboard/Login";
import ForgotPassword from "./pages/dashboard/ForgotPassword";
import Register from "./pages/dashboard/Register";

function App() {
  const [snack, setSnackState] = useState({
    message: "",
    severity: "success",
    duration: 3000,
    open: false,
    variant: "filled",
    close: (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSnack({ open: false });
    },
  });

  function setSnack(props) {
    setSnackState({ ...snack, ...props });
  }

  return (
    <SnackbarContext.Provider value={{ snack, setSnack }}>
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.duration}
        onClose={snack.close}
      >
        <Alert
          variant={snack.variant}
          severity={snack.severity}
          onClose={snack.close}
        >
          {snack.message}
        </Alert>
      </Snackbar>

      {process.env.REACT_APP_NODE_ENV === "development" && (
        <Grid
          style={{
            backgroundColor: "red",
            textAlign: "center",
            color: "white",
          }}
        >
          ESSE É UM AMBIENTE DE TESTE. USE SEM MEDO :)
        </Grid>
      )}
      <Router>
        <Switch>
          <ProtectedRoute path="/dashboard">
            <Dashboard />
          </ProtectedRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </SnackbarContext.Provider>
  );
}

export default App;
