import { cpf, cnpj } from "cpf-cnpj-validator";

function validateNumber(number) {
  number = number
    .split("_")
    .join("")
    .split("-")
    .join("")
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "");
  if (number.length < 10)
    return { valid: false, text: "Informe um telefone válido." };
  return { valid: true, text: "" };
}

function validateCEP(cep) {
  cep = cep
    .split("_")
    .join("")
    .split("-")
    .join("")
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "");
  if (cep.length !== 8) return { valid: false, text: "Informe um CEP válido." };
  return { valid: true, text: "" };
}

function validateCPF(_cpf) {
  _cpf = _cpf
    .split("_")
    .join("")
    .split("-")
    .join()
    .split(".")
    .join()
    .split(",")
    .join("");
  if (_cpf.length > 0 && !cpf.isValid(_cpf)) {
    return { valid: false, text: "Infome um CPF válido." };
  }
  return { valid: true, text: "" };
}

function validateCNPJ(_cnpj) {
  _cnpj = _cnpj
    .split("_")
    .join("")
    .split("-")
    .join()
    .split(".")
    .join()
    .split("/")
    .join("")
    .split(",")
    .join("");
  if (_cnpj.length > 0 && !cnpj.isValid(_cnpj)) {
    return { valid: false, text: "Infome um CNPJ válido." };
  }
  return { valid: true, text: "" };
}

function validatePassword(password) {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,72}$/ 
  if (!password || !regex.test(password)) {
    return { valid: false, text: "Senha deve ter entre 6 e 72 dígitos, contendo pelo menos uma letra minúscula, uma maiúscula e um número." };
  }
  
  return { valid: true, text: "" };
}

function validatePassword2(password2, password) {
  console.log(password2, password);
  // Se está valido a primeira eu deixo passar
  if (!validatePassword(password).valid) return { valid: true, text: "" };
  if (!validatePassword(password2).valid)
    return { valid: false, text: "Senha deve ter entre 6 e 72 dígitos, contendo pelo menos uma letra minúscula, uma maiúscula e um número." };
  if (password !== password2)
    return { valid: false, text: "As senhas digitadas não são iguais." };
  return { valid: true, text: "" };
}

function validateEmail(email) {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (email.length > 0 && !regex.test(email))
    return { valid: false, text: "Informe um email válido." };
  return { valid: true, text: "" };
}

function validateGeneric(field) {
  if (!field || !field.toString().length || !field.length)
    return { valid: false, text: "Esse campo é obrigatório." };

  return { valid: true, text: "" };
}

function validateState(field) {
  if (!field || !field.toString().length || !field.length || field.length !== 2)
    return { valid: false, text: "O campo estado deve possuir exatamente dois caracteres." };

  return { valid: true, text: "" };
}


function validateDate(date) {
  if (date.length !== 10) {
    return { valid: false, text: "Infome uma data válida." };
  } 
  const aux = date.toString().split('/');
  if(aux.length !== 3 || aux[0].length !== 2 || aux[1].length !== 2 || aux[2].length !== 4 || date.indexOf('_') !== -1) {
    return { valid: false, text: "Infome uma data válida." };
  }
  const atual = new Date();
  if(Number(aux[2]) > atual.getFullYear())
    return { valid: false, text: "Infome uma data válida." };
  else if (Number(aux[2]) === atual.getFullYear()) {
    const mon = atual.getMonth() + 1;
    if(Number(aux[1]) > mon) {
      return { valid: false, text: "Infome uma data válid a." };
    } else if (Number(aux[1]) === mon) {
      const dia = (atual.getDate());
      console.log('aqui', dia, aux[0])
      if(Number(aux[0]) > dia) {
      return { valid: false, text: "Infome uma data válida." };
      }
    }
  }
  return { valid: true, text: "" };
}

export {
  validateCPF,
  validatePassword,
  validateGeneric,
  validateEmail,
  validatePassword2,
  validateCNPJ,
  validateNumber,
  validateCEP,
  validateDate,
  validateState
};
