import React from "react";
import { Button } from "@material-ui/core";

import { Modal } from "react-bootstrap";
import "../assets/css/components/confirmationPopup.css";
function ConfirmationPopForgetPassword({ onHide, show, confirm }) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="dialog"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Alteração de senha
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Para alterar sua senha, entre em contato com a administração do
          sistema!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancelar</Button>
        <Button onClick={confirm}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationPopForgetPassword;
