import React, { useState } from "react";

import Logo from "../../assets/img/logo2.png";
import "../../assets/css/pages/forgotPassword.css";
import {
  Typography,
  Grid,
  Container,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Cancel, Mail } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import DoubleDiv from "../../components/DoubleDiv";
import useErrors from "../../hooks/useErrors";
import { validateEmail } from "../../utils/validations";
import ButtonRounded from "../../components/ButtonRounded";

function ForgotPassword() {
  const { state } = useLocation();
  const history = useHistory();
  const validations = { email: validateEmail };
  const [errors, checkFields] = useErrors(validations);
  const [values, setValues] = useState({
    email: "",
    loading: false,
    hasError: false,
    errorMessage: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function close() {
    history.replace(state?.from || "/login", {});
  }

  function submit() {}

  return (
    <div className="mainForgotPassword">
      <Typography className="closeForgotPassword" onClick={close}>
        <Cancel />
      </Typography>
      <div className="contentForgotPassword">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <img
              alt="Logo ControBank"
              src={Logo}
              className="logoForgotPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className="titleForgotPassword">
              Recuperação de senha
            </Typography>
          </Grid>
          <Container
            space={4}
            maxWidth="sm"
            style={{
              "textAlign": "center",
              position: "relative",
              height: "100%",
              marginTop: "220px",
              maxWidth: "80%",
            }}
          >
            <DoubleDiv values={values} top={'calc(50% - 80px)'}>
              <form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  submit();
                }}
                className="formContent"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      variant="outlined"
                      label="Email"
                      required
                      name="email"
                      error={!errors.email.valid}
                      helperText={errors.email.text}
                      onBlur={checkFields}
                      disabled={values.loading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mail />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={12} style={{'textAlign': 'center'}}>
                    <Typography variante="body1">
                      Você receberá por e-mail um link para cadastrar uma nova
                      senha.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ButtonRounded
                      disabled={values.loading}
                      type="submit"
                      style={{ marginTop: "20px" }}
                      color={"var(--color-blue)"}
                      text={"Enviar"}
                    ></ButtonRounded>
                  </Grid>
                </Grid>
              </form>
            </DoubleDiv>
          </Container>
        </Grid>
      </div>
    </div>
  );
}
export default ForgotPassword;
