import React from "react";

const ValidationsForm = React.createContext({
  name: empty,
  email: empty,
  cpf: empty,
  password: empty,
  password2: empty,
  cep: empty
});

function empty() {
  return { valid: true, text: "" };
}

export default ValidationsForm;
