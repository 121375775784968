import React from "react";
import { Alert } from "@material-ui/lab";
import {
  Container,
} from "@material-ui/core";
import "../assets/css/components/doubleDiv.css";
function DoubleDiv({ children, values, top, transform = 'translate(-50%,-50%)' }) {
  return (
    <>
      <div className="contentDoubleDiv" style={{'top': top, transform}}>
      <Container style={{'margin' : '0 !important', 'marginBottom' : '20px'}}>
        {values.hasError && (
          <Alert severity="warning">{values.errorMessage}</Alert>
        )}
      </Container>
        <div className="thirdDoubleDiv">
          <div className="secondDoubleDiv">
            <div className="firstDoubleDiv" style={{ padding: "20px", maxHeight: '90vh', overflowY: 'auto'}}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoubleDiv;
