import React from "react";
import { Button } from "@material-ui/core";

import '../assets/css/components/buttonRounded.css';
function ButtonRounded({ text, color, shadowLight, disabled, type, onClick, style}) {

   style = {
     ...style, 
    'boxShadow': '5px 5px 5px -2px ' + (shadowLight ? 'var(--color-gray)' : 'var(--color-gray-dark)'),
    'backgroundColor': color ? color : 'var(--color-orange)',
  }

  

  return (
   <Button className="buttonRounded" onClick={onClick} style={style} type={type} disabled={disabled}>{text}</Button>
  );
}

export default ButtonRounded;
