import React, { useContext, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import ValidationsForm from "../../contexts/ValidationsForm";
import useErrors from "../../hooks/useErrors";
import InputMask from "react-input-mask";
import ButtonRounded from "../../components/ButtonRounded";
import Address from "./Address";

function FormRegisterCompanyData({ next, values, setValues }) {
  const validations = useContext(ValidationsForm);
  const [errors, checkFields, canSubmit] = useErrors(validations);
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      company: { ...values.company, [prop]: event.target.value },
    });
  };

  const setAddress = (field, value) => {
    const newState = values;
    newState.company.address[field] = value;
    setValues({ ...newState });
  };

  const submit = () => {
    if (canSubmit()) next();
  };

  const [fileList, setFileList] = useState(null);

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    setFileList(e.target.files);
    const files = e.target.files ? [...e.target.files] : [];
    const documents = [];
    for await (const file of files) {
      const x = await fileToBase64(file);

      documents.push({
        content: x.split(',')[1],
        type: "UNKNOWN",
      });
    }
    setValues({
      ...values,
      company: { ...values.company, documents },
    });
  };

  // 👇 files is not an array, but it's iterable, spread to get an array of files
  const files = fileList ? [...fileList] : [];

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        submit();
      }}
      className="formContent"
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.company.name}
            onChange={handleChange("name")}
            variant="outlined"
            name="name"
            label="Nome da empresa"
            disabled={values.loading}
            required
            error={!errors.name.valid}
            helperText={errors.name.text}
            onBlur={checkFields}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.company.companyName}
            onChange={handleChange("companyName")}
            variant="outlined"
            name="companyName"
            label="Nome fantasia"
            disabled={values.loading}
            required
            error={!errors.name.valid}
            helperText={errors.name.text}
            onBlur={checkFields}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.company.email}
            onChange={handleChange("email")}
            variant="outlined"
            label="Email da empresa"
            name="email"
            disabled={values.loading}
            error={!errors.email.valid}
            helperText={errors.email.text}
            onBlur={checkFields}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            mask="99/99/9999"
            disabled={values.loading}
            value={values.company.date}
            onChange={handleChange("date")}
            name="date"
            onBlur={checkFields}
            required
          >
            {() => (
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Data de criação"
                name="date"
                error={!errors.date.valid}
                helperText={errors.date.text}
                required
              />
            )}
          </InputMask>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            mask="(99) 9999-99999"
            disabled={values.loading}
            value={values.company.number}
            onChange={handleChange("number")}
            name="number"
            onBlur={checkFields}
          >
            {() => (
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Telefone"
                name="number"
                error={!errors.number.valid}
                helperText={errors.number.text}
                required
              />
            )}
          </InputMask>
        </Grid>

        <Grid item md={6} xs={12}>
          <InputMask
            mask="99.999.999/9999-99"
            disabled={values.loading}
            value={values.company.cnpj}
            onChange={handleChange("cnpj")}
            name="cnpj"
            onBlur={checkFields}
          >
            {() => (
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="CNPJ"
                name="cnpj"
                error={!errors.cnpj.valid}
                helperText={errors.cnpj.text}
                required
              />
            )}
          </InputMask>
        </Grid>
        <Address address={values.company.address} setValues={setAddress} />
        <Grid item xs={12} md={12}>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                fontWeight: "semibold",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Documentação da empresa
            </span>
            <input
              type="file"
              onChange={handleFileChange}
              multiple
              accept="application/pdf"
            />

            <ul>
              {files.map((file, i) => (
                <li key={i}>
                  {file.name} - {file.type}
                </li>
              ))}
            </ul>
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <ButtonRounded
            disabled={values.loading}
            type="submit"
            style={{ marginTop: "20px" }}
            text={"Próximo"}
          ></ButtonRounded>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormRegisterCompanyData;
