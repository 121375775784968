import React from "react";
import { useHistory } from "react-router-dom";
import "../../assets/css/pages/home.css";
import Logo from "../../assets/img/logo.png";
import LogoPix from "../../assets/img/pix-logo.svg";
import Bg from "../../assets/img/arte-bg-home.svg";
import { Grid, Container, Typography } from "@material-ui/core";
import DoubleDiv from "../../components/DoubleDiv";
import ButtonRounded from "../../components/ButtonRounded";

function Home() {
  const history = useHistory();
  
  function goLogin() {
    history.push('/login');
  }

  function goRegister() {
    history.push('/register');
  }

  return (
    <main className="mainHome">
      <Grid container spacing={0}>
        <Grid item xs={6} className="leftsideHome">
          <img alt="Logo ControBank" src={Logo} className="logoHome" />
          <Container space={4} maxWidth="sm" style={{ "textAlign": "center", 'position': 'relative', 'height': '100%'}}>
            <DoubleDiv values={{}} top={'calc(50% - 150px)'}>
              <ButtonRounded 
                onClick={goLogin}
                text={"Entrar"}
                color={"var(--color-orange-light)"}
                shadowLight={true}
              ></ButtonRounded>

              <Typography variant="subtitle1" paragraph={true} style={{'marginTop': '16px'}}>
                <b style={{ fontWeight: "bold" }}>Primeiro acesso? <br/>Faça já seu cadastro</b>
              </Typography>
              <ButtonRounded onClick={goRegister} text={"Cadastrar"}></ButtonRounded>
            </DoubleDiv>
          </Container>
        </Grid>
        <Grid item xs={6} className="rightsideHome">
          <img alt="Logo PIX" src={LogoPix} className="logoPixHome" />
          <img alt="background" src={Bg} className="backgroundHome" />
        </Grid>
      </Grid>
    </main>
  );
}

export default Home;
