import React, { useEffect, useState, useContext } from "react";

import requestAdapter from "../../api/request";
import UserContext from "../../contexts/User";
import SnackbarContext from "../../contexts/Snackbar";
import ButtonRounded from "../../components/ButtonRounded";
import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function Receive() {
  const { user } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [pixAlias, setPixAlias] = useState(null);
  const [lookedPixAlias, setLookedPixAlias] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState({ hasError: false, errorMessage: "" });
  const [loadingQr, setLoadingQr] = useState(false);
  const [qrCode, setQrCode] = useState();
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get(`/pix/${user.accountId}`);
        if (res.status === 200) {
          if (res.data && res.data.aliases && res.data.aliases.length) {
            setPixAlias(res.data.aliases[0].name);
          }
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
      setLookedPixAlias(true);
    };
    getData();
  });

  async function generatePixAlias() {
    setLoading(true);
    try {
      const res = await requestAdapter.post(`/pix/`, {
        externalIdentifier: user.externalIdentifier,
      });
      if (res.status) {
        setSnack({
          message: "Solicitação de criação de chave realizada com sucesso",
          open: true,
        });
      }
    } catch (ex) {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
    setLoading(false);
  }

  async function getNewPayment() {
    if (!value) {
      setError({ hasError: true, errorMessage: "Informe um valor válido!" });
      return;
    }
    setQrCode(null);
    setLoadingQr(true);
    try {
      const res = await requestAdapter.post(`/transaction-tofront`, {
        value,
      });
      setError({ hasError: false, errorMessage: "Informe um valor válido!" });
      if (res.status) {
        setQrCode(res.data.data);
      }
    } catch (ex) {
      console.log(ex);
      setError({ hasError: true, errorMessage: ex.message });
    }
    setLoadingQr(false);
  }

  function copy() {
    navigator.clipboard.writeText(pixAlias);
    setSnack({
      message: "Chave PIX copiada para área de transferência",
      open: true,
      severity: "info",
    });
  }

  async function copyQrCode(blob) {
    fetch(blob)
      .then((res) => res.blob())
      .then((res) => {
        const item = new window.ClipboardItem({ "image/png": res });
        navigator.clipboard.write([item]);
        setSnack({
          message: "QRCode copiado para área de transferência",
          open: true,
          severity: "info",
        });
      });
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          marginTop: "25px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Cobranças</Typography>
              <Divider
                spacing={4}
                style={{
                  background: "var(--color-orange-mid)",
                  width: "15%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Typography variant="body1">
                Solicitar uma nova cobrança PIX por QRCode
              </Typography>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  onChange={(ev) => setValue(ev.target.value)}
                  variant="outlined"
                  label="Valor desejado"
                  required
                  name="value"
                  disabled={loadingQr}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ButtonRounded
                  disabled={loadingQr}
                  onClick={() => getNewPayment()}
                  text={"Gerar QRCode"}
                ></ButtonRounded>
              </Grid>
            </Grid>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 10,
              }}
            />
            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs={12}>
                {!lookedPixAlias && <div>Carregando suas informações...</div>}
                {lookedPixAlias && !pixAlias && (
                  <div>
                    Você não possui nenhuma chave PIX cadastrada!
                    <br />
                    <ButtonRounded
                      disabled={loading}
                      type="submit"
                      text={"Gerar nova chave"}
                      onClick={() => generatePixAlias()}
                    ></ButtonRounded>
                  </div>
                )}
                {lookedPixAlias && pixAlias && (
                  <div>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        Sua chave pix é:
                      </Grid>
                      <Grid item xs={12}>
                        <span style={{ fontWeight: "bold" }}>{pixAlias}</span>{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            textDecorationLine: "underline",
                            color: "blue",
                          }}
                          onClick={() => copy()}
                        >
                          Copiar
                        </span>
                      </Grid>
                    </Grid>

                    <br />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>

          {error.hasError && (
            <Alert severity="warning" style={{ marginTop: "20px" }}>
              {error.errorMessage}
            </Alert>
          )}
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{ marginTop: "20px" }}
          >
            {loadingQr && (
              <Grid item xs={3}>
                <CircularProgress></CircularProgress>
              </Grid>
            )}
            {qrCode && (
              <Grid item xs={3}>
                <img
                  alt="Logo ControBank"
                  src={"data:image/png;base64," + qrCode.image.imageContent}
                />
                {qrCode.qrcodeUrl}
                <span
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "blue",
                  }}
                  onClick={() =>
                    copyQrCode(
                      "data:image/png;base64," + qrCode.image.imageContent
                    )
                  }
                >
                  Copiar QRCode
                </span>
                <br />
              </Grid>
            )}
            {qrCode && process.env.REACT_APP_NODE_ENV === "development" && (
              <Grid item xs={12}>
                {qrCode.textContent}
              </Grid>
            )}
          </Grid>
      </Grid>
    </div>
  );
}

export default Receive;
