import React from "react";
import { Button } from "@material-ui/core";

import { Modal } from 'react-bootstrap';
import "../assets/css/components/confirmationPopup.css";
function ConfirmationPopUpTransfer({ onHide, show, data, value, alias, confirm }) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="dialog"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Transferência PIX
        </Modal.Title>
      </Modal.Header>
   {data.aliasAccountHolder &&  <Modal.Body>
    <p>Quem vai receber: <br/> <b style={{ fontWeight: "bold" }}>{data.aliasAccountHolder.name}</b></p>
    <p>CPF: <br/> <b style={{ fontWeight: "bold" }}>{data.aliasAccountHolder.taxIdentifier.taxIdMasked}</b></p>
    <p>Banco: <br/> <b style={{ fontWeight: "bold" }}>{data.psp.name}</b></p>
    <p>Agência / Conta: <br/> <b style={{ fontWeight: "bold" }}>{data.accountDestination.branch} / {data.accountDestination.account} </b></p>
    <p>Chave: <br/> <b style={{ fontWeight: "bold" }}>{alias} </b></p>
    <p>Valor: <br/> <b style={{ fontWeight: "bold" }}>R$ {parseFloat(value).toFixed(2)} </b></p>
    </Modal.Body>}
      <Modal.Footer>
        <Button onClick={onHide}>Fechar</Button>
        <Button onClick={confirm}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationPopUpTransfer;
