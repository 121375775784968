function justNumbers(num, inString = false) {
  let str = num + '';
  let ans = ''
  for(let x in str) {
    if(str[x] >= '0' && str[x] <= '9') {
      ans += str[x];
    }
  }
  if(inString) {
    return ans + "";
  }
  return Number(ans);
}

export {
  justNumbers
}