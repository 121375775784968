import React, { useContext } from "react";
import { Grid, TextField } from "@material-ui/core";
import ValidationsForm from "../../contexts/ValidationsForm";
import useErrors from "../../hooks/useErrors";
import InputMask from "react-input-mask";
import ButtonRounded from "../../components/ButtonRounded";
import Address from "./Address";

function FormRegisterPersonalData({ next, back, values, setValues }) {
  const validations = useContext(ValidationsForm);
  const [errors, checkFields, canSubmit] = useErrors(validations);
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      personal: { ...values.personal, [prop]: event.target.value },
    });
  };

  const setAddress = (field, value) => {
    const newState = values;
    newState.personal.address[field] = value;
    setValues({ ...newState });
  };

  const submit = () => {
    if (canSubmit()) next();
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  const handleFileChangeSelfie = async (e) => {
    const files = e.target.files ? [...e.target.files] : [];

    for await (const file of files) {
      const x = await fileToBase64(file);

      setValues({
        ...values,
        personal: {
          ...values.personal,
          picture: {
            content: x.split(',')[1],
            type: "PICTURE",
          },
        },
      });
    }
  };

  const handleFileChangeCNH = async (e) => {
    const files = e.target.files ? [...e.target.files] : [];

    for await (const file of files) {
      const x = await fileToBase64(file);

      setValues({
        ...values,
        personal: {
          ...values.personal,
          cnh: {
            content: x.split(',')[1],
            type: "CNH",
          },
        },
      });
    }
  };

  const handleFileChangeIdentity_front = async (e) => {
    const files = e.target.files ? [...e.target.files] : [];

    for await (const file of files) {
      const x = await fileToBase64(file);

      setValues({
        ...values,
        personal: {
          ...values.personal,
          identity_front: {
            content: x.split(',')[1],
            type: "IDENTITY_FRONT",
          },
        },
      });
    }
  };

  const handleFileChangeIdentity_back = async (e) => {
    const files = e.target.files ? [...e.target.files] : [];

    for await (const file of files) {
      const x = await fileToBase64(file);

      setValues({
        ...values,
        personal: {
          ...values.personal,
          identity_back: {
            content: x.split(',')[1],
            type: "IDENTITY_BACK",
          },
        },
      });
    }
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        submit();
      }}
      className="formContent"
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.personal.name}
            onChange={handleChange("name")}
            variant="outlined"
            name="name"
            label="Nome completo"
            disabled={values.loading}
            required
            error={!errors.name.valid}
            helperText={errors.name.text}
            onBlur={checkFields}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.personal.mother}
            onChange={handleChange("mother")}
            variant="outlined"
            name="mother"
            label="Nome da mãe"
            disabled={values.loading}
            required
            error={!errors.name.valid}
            helperText={errors.name.text}
            onBlur={checkFields}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.personal.email}
            onChange={handleChange("email")}
            variant="outlined"
            label="Email"
            name="email"
            disabled={values.loading}
            error={!errors.email.valid}
            helperText={errors.email.text}
            onBlur={checkFields}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            mask="99/99/9999"
            disabled={values.loading}
            value={values.personal.date}
            onChange={handleChange("date")}
            name="date"
            onBlur={checkFields}
            required
          >
            {() => (
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Data de nascimento"
                name="date"
                error={!errors.date.valid}
                helperText={errors.date.text}
                required
              />
            )}
          </InputMask>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            mask="(99) 9999-99999"
            disabled={values.loading}
            value={values.personal.number}
            onChange={handleChange("number")}
            name="number"
            onBlur={checkFields}
          >
            {() => (
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Telefone"
                name="number"
                error={!errors.number.valid}
                helperText={errors.number.text}
                required
              />
            )}
          </InputMask>
        </Grid>

        <Grid item md={6} xs={12}>
          <InputMask
            mask="999.999.999-99"
            disabled={values.loading}
            value={values.personal.cpf}
            onChange={handleChange("cpf")}
            name="cpf"
            onBlur={checkFields}
          >
            {() => (
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="CPF"
                name="cpf"
                error={!errors.cpf.valid}
                helperText={errors.cpf.text}
                required
              />
            )}
          </InputMask>
        </Grid>
        <Address address={values.personal.address} setValues={setAddress} />
        <Grid item xs={12} md={12}>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                fontWeight: "semibold",
                fontSize: 20,
                textAlign: "center",
              }}
            >
              Documentação do representante
            </span>

            <span style={{ textDecorationLine: "underline" }}>
              Foto estilo selfie
            </span>
            <input
              type="file"
              onChange={handleFileChangeSelfie}
              accept="image/png, image/gif, image/jpeg"
            />

            <span style={{ textDecorationLine: "underline" }}>
              Frente do CNH
            </span>
            <input
              type="file"
              onChange={handleFileChangeCNH}
              accept="image/png, image/gif, image/jpeg"
            />

            <span style={{ textDecorationLine: "underline" }}>
              Frente da identidade
            </span>
            <input
              type="file"
              onChange={handleFileChangeIdentity_front}
              accept="image/png, image/gif, image/jpeg"
            />

            <span style={{ textDecorationLine: "underline" }}>
              Traseira da identidade
            </span>
            <input
              type="file"
              onChange={handleFileChangeIdentity_back}
              accept="image/png, image/gif, image/jpeg"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <ButtonRounded
            disabled={values.loading}
            onClick={back}
            style={{ marginTop: "20px" }}
            text={"Voltar"}
            shadowLight={true}
            color={"var(--color-gray-dark)"}
          ></ButtonRounded>
          <ButtonRounded
            disabled={values.loading}
            type="submit"
            style={{ marginTop: "20px", marginLeft: "10px" }}
            text={"Próximo"}
          ></ButtonRounded>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormRegisterPersonalData;
