import logger from '../utils/logger';
const axios = require('axios');

async function doRequest(url, method, body, headers) {
  headers = headers || {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  };

  const jwtToken = localStorage.getItem('token');
  if (jwtToken) {
      headers['Authorization'] = `Bearer ${jwtToken}`;
  }

  try {
    const URL = `${process.env.REACT_APP_API_URL}${url}`;
    const options = {
      method,
      url: URL,
      headers,
      data: body,
      transformResponse: [(data) => {
        const json = JSON.parse(data);
        if(json.ok) {
          if(json.token) {
            localStorage.setItem('token', json.token);
          }
          return json;
        } else {
          throw json;
        }
      }]
    }
    return axios(options);
  } catch (ex) {
      logger.error(ex);
      throw new Error(ex.message || ex.statusText);
  }
}

const requestAdapter = { request: doRequest };
for (const httpMethod of [ 'get', 'post', 'put', 'patch', 'delete' ]) {
  requestAdapter[httpMethod] = (url, body, headers) => doRequest(
      url, httpMethod, body, headers
  );
}
export default requestAdapter ;