import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography,
} from "@material-ui/core";
import requestAdapter from "../../api/request";

import SnackbarContext from "../../contexts/Snackbar";
import { Alert } from "@material-ui/lab";
import ButtonRounded from "../../components/ButtonRounded";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Settings() {
  const [externalIdentifier, setExternalIdentifier] = useState();
  const [hasKey, setHasKey] = useState(false);
  const [looked, setLooked] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const [dialog] = useState({
    text: "Ao gerar uma nova chave de acesso todos os antigos aplicativos irão parar de funcionar e deverão ter suas chave de acesso atualizada. Você confirma?",
    title: "Confirma a alteração?",
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ hasError: false, errorMessage: "" });
  const [integration, setIntegration] = useState();
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get(`/integration`);
        if (res.status === 200) {
          setExternalIdentifier(res.data.externalIdentifier);
          setHasKey(res.data.key);
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
      setLooked(true);
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getNewKey() {
    if(!hasKey) {
      confirm();
    } else {
      setOpen(true);
    }
    
  }
  const handleClose = () => {
    setOpen(false);
  };

  const confirm = async () => {
    setOpen(false);
    setLoading(true);
    setError({ hasError: false });
    try {
      const res = await requestAdapter.get(`/integration-secret`);
      if (res.status === 200) {
        console.log(res);
        setIntegration(res.data.key);
        setHasKey(res.data.key);
      }
    } catch (ex) {
      setError({ hasError: true, errorMessage: ex.message });
    }
    setLoading(false);
  };

  function copy(cp, text) {
    navigator.clipboard.writeText(cp);
    setSnack({
      message: text,
      open: true,
      severity: "info",
    });
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button onClick={confirm}>Sim</Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{
          marginTop: "25px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "25px", borderWidth: 1, borderColor: "black" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Configurações</Typography>
              <Divider
                spacing={4}
                style={{
                  background: "var(--color-orange-mid)",
                  width: "20%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Typography variant="body">
                {!looked && <div>Carregando suas informações...</div>}
                {looked && (
                  <div>
                    Seu identificador externo (externalIdentifier) é:
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      {externalIdentifier}
                    </span>{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        color: "blue",
                      }}
                      onClick={() =>
                        copy(
                          externalIdentifier,
                          "Identificador copiado para a área de transferência."
                        )
                      }
                    >
                      Copiar
                    </span>
                  </div>
                )}
              </Typography>
            </Grid>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 10,
              }}
            />

            {looked && (
              <Grid item xs={12}>
                <Typography variant="body">
                  <div>
                    Chave de acesso:
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      {hasKey
                        ? "Uma chave de acesso já foi gerada anteriormente!"
                        : "Você não possui nenhuma chave de acesso!"}
                    </span>{" "}
                    <Grid container justify="flex-end">
                      <Grid item xs={12}>
                        <ButtonRounded
                          disabled={loading}
                          onClick={() => getNewKey()}
                          text={"Gerar nova chave"}
                        ></ButtonRounded>
                      </Grid>
                    </Grid>
                  </div>
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
        {error.hasError && (
          <Grid container justify="center">
            <Grid item xs={12} sm={4}>
              <Alert severity="warning" style={{ marginTop: "20px" }}>
                {error.errorMessage}
              </Alert>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={0}
        justify="center"
        style={{ marginTop: "10px" }}
      >
        {loading && (
          <Grid item xs={3}>
            <CircularProgress></CircularProgress>
          </Grid>
        )}
        {integration && (
          <Grid item style={{textAlign: 'center'}}>
                  <Alert severity="error" style={{ marginTop: "20px" }}>
                  Sua nova chave de acesso (key) é:<br/>
            <span style={{ fontWeight: "bold" }}> {integration} </span> <span
                      style={{
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        color: "blue",
                      }}
                      onClick={() =>
                        copy(
                          integration,
                          "Chave de acesso copiado para a área de transferência."
                        )
                      }
                    >
                      Copiar
                    </span><br />
            Guarde em um local seguro.
              </Alert>
            
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Settings;
