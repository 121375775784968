import React, { useState, useEffect, useContext } from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import requestAdapter from "../../api/request";

import SnackbarContext from "../../contexts/Snackbar";
import { Alert } from "@material-ui/lab";
import ButtonRounded from "../../components/ButtonRounded";

function Profile() {
  const [fee, setFee] = useState({
    mediatorFeePixIn: "",
    mediatorFeePixOut: "",
  });
  const [looked, setLooked] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ hasError: false, errorMessage: "" });
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPassword2, setNewPassword2] = useState();
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get(`/fee`);
        if (res.status === 200) {
          fee.mediatorFeePixOut = res.data.fee.mediatorFeePixOut;
          fee.mediatorFeePixIn = res.data.fee.mediatorFeePixIn;
          setFee({ ...fee });
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
      setLooked(true);
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async () => {
    setError({ hasError: false });
    if (!currentPassword || !currentPassword.length) {
      setError({ hasError: true, errorMessage: "Informe a senha atual!" });
      return;
    }

    if (!newPassword || !newPassword.length) {
      setError({ hasError: true, errorMessage: "Informe a nova senha!" });
      return;
    }
    if (!newPassword2 || !newPassword2.length) {
      setError({
        hasError: true,
        errorMessage: "Informe a confirmação de senha!",
      });
      return;
    }

    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,72}$/;
    if (!newPassword || !regex.test(newPassword)) {
      setError({
        hasError: true,
        errorMessage:
          "A nova senha deve ter entre 6 e 72 dígitos, contendo pelo menos uma letra minúscula, uma maiúscula e um número.",
      });
      return;
    }

    if (newPassword !== newPassword2) {
      setError({
        hasError: true,
        errorMessage: "As senhas digitadas não são iguais!",
      });
      return;
    }

    setLoading(true);
    setError({ hasError: false });
    try {
      const res = await requestAdapter.post(`/change-password`, {password: currentPassword, newPassword: newPassword});
      if (res.status === 200) {
        setSnack({
          message: "Senha alterada com sucesso",
          open: true,
          severity: "success",
        });
        setCurrentPassword('');
        setNewPassword('');
        setNewPassword2('');
      }
    } catch (ex) {
      setError({ hasError: true, errorMessage: ex.message });
    }
    setLoading(false);
  };

  // function copy(cp, text) {
  //   navigator.clipboard.writeText(cp);
  //   setSnack({
  //     message: text,
  //     open: true,
  //     severity: "info",
  //   });
  // }

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          marginTop: "25px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "25px", borderWidth: 1, borderColor: "black" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Meu Perfil</Typography>

              <Divider
                spacing={4}
                style={{
                  background: "var(--color-orange-mid)",
                  width: "15%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Minhas tarifas</Typography>
              <Typography variant="body1">
                {!looked && <span>Carregando suas informações...</span>}
                {looked && (
                  <span>
                    Tarifa de recebimento de PIX:{" "}
                    <b style={{ fontWeight: "bold" }}>R$ {fee.mediatorFeePixIn}</b>
                    <br />
                    Tarifa de transferência via PIX:{" "}
                    <b style={{ fontWeight: "bold" }}>R$ {fee.mediatorFeePixOut}</b>
                  </span>
                )}
              </Typography>
            </Grid>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Grid item xs={12}>
              <Typography variant="h6">Alteração de senha</Typography>
              <Grid container spacing={2} style={{ paddingTop: "5px" }}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="password"
                    size="small"
                    value={currentPassword}
                    onChange={(ev) => setCurrentPassword(ev.target.value)}
                    variant="outlined"
                    name="password"
                    label="Senha atual"
                    disabled={loading}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    type="password"
                    value={newPassword}
                    onChange={(ev) => setNewPassword(ev.target.value)}
                    variant="outlined"
                    name="password"
                    label="Nova senha"
                    disabled={loading}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="password"
                    size="small"
                    value={newPassword2}
                    onChange={(ev) => setNewPassword2(ev.target.value)}
                    variant="outlined"
                    name="password"
                    label="Confirmar nova senha"
                    disabled={loading}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "5px" }}>
                <ButtonRounded
                  disabled={loading}
                  onClick={() => submit()}
                  text={"Alterar senha"}
                ></ButtonRounded>
              </Grid>
              {error.hasError && (
                <Grid container justify="center">
                  <Grid item xs={12} sm={4}>
                    <Alert severity="warning" style={{ marginTop: "20px" }}>
                      {error.errorMessage}
                    </Alert>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={0}
                justify="center"
                style={{ marginTop: "10px" }}
              >
                {loading && (
                  <Grid item xs={3}>
                    <CircularProgress></CircularProgress>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 10,
              }}
            /> */}
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default Profile;
