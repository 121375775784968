import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useContext, useState } from "react";

import SnackbarContext from "../../contexts/Snackbar";
import requestAdapter from "../../api/request";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import ButtonRounded from "../../components/ButtonRounded";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function MyQrCode() {
  const { setSnack } = useContext(SnackbarContext);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [initalDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [input, setInput] = useState(true);
  const [output, setOutput] = useState(true);
  
  const [open, setOpen] = React.useState(false);
  const [transactionDetail, setTransactionDetail] = useState({});

  const handleClickOpen = async (id) => {
    try {
      const res = await requestAdapter.get(`/transaction-detail/${id}`);
      if (res.status === 200) {
        if (res.data) {
          setTransactionDetail(res.data.data[0]);
          setOpen(true);
        }
      }
    } catch {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get(`/transactions-qrcode`);
        if (res.status === 200) {
          if (res.data) {
            let rows = res.data.data;
            rows = rows.sort((a, b) => (a.transactionDate > b.transactionDate ? -1 : 1));
            setRows(rows);
            setFilteredRows(rows);
          }
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function formatDate(d) {
    if (!d) return "";
    const x = d.split("T");
    const data = x[0].split("-");
    return `${data[2]}/${data[1]}/${data[0]} ${x[1].split(".")[0]}`;
  }

  function search() {
    let aux = rows;
    if (!input) {
      aux = aux.filter((a) => a.status_name !== "APPROVED");
    }
    if (!output) {
      aux = aux.filter((a) => a.status_name === "APPROVED");
    }
    if (initalDate) {
      aux = aux.filter((a) => a.updatedAt.split("T")[0] >= initalDate);
    }
    if (finalDate) {
      aux = aux.filter((a) => a.updatedAt.split("T")[0] <= finalDate);
    }
    handleChangePage({}, 0);
    setFilteredRows(aux);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: "600px" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ textAlign: "center" }} variant="body1">
              <b style={{ fontWeight: "bold" }}>Transações - Detalhamento</b>
            </Typography>
            <Divider
              style={{
                background: "var(--color-orange-mid)",
                width: "20%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20,
                textAlign: "center",
              }}
            />
            {!transactionDetail && (
              <Typography style={{ textAlign: "center" }} variant="body1">
                Não foi possível encontrar a transação selecionada
              </Typography>
            )}
            {transactionDetail && (
              <Typography variant="body2">
                Data da transação:{" "}
                <b style={{ fontWeight: "bold" }}>{formatDate(transactionDetail.createdAt)}</b>
                <br />
                Tipo da transação:{" "}
                <b style={{ fontWeight: "bold" }}>{transactionDetail.type === "C" ? "Entrada" : "Saída"}</b>
                <br />
                Valor da transação:{" "}
                <b style={{ fontWeight: "bold" }}>
                  {transactionDetail.type === "C" ? "+" : "-"}R${" "}
                  {Number(transactionDetail.totalAmount).toFixed(2)}
                </b>
                <br />
                {transactionDetail.fee && (
                  <span>
                    Taxas: <b style={{ fontWeight: "bold" }}>-R$ {transactionDetail.fee}</b>
                    <br />
                  </span>
                )}
                {transactionDetail.type === "C" && (
                  <span>
                    Recebido de:{" "}
                    <b style={{ fontWeight: "bold" }}>
                      {transactionDetail.sender_name} -{" "}
                      {transactionDetail.sender_taxidmasked} /{" "}
                      {transactionDetail.sender_pspname}
                    </b>
                    <br />
                  </span>
                )}
                {transactionDetail.type === "D" && (
                  <span>
                    Enviado para:{" "}
                    <b style={{ fontWeight: "bold" }}>
                      {transactionDetail.receiver_name} -{" "}
                      {transactionDetail.receiver_taxidmasked} /{" "}
                      {transactionDetail.receiver_pspname}
                    </b>
                    <br />
                  </span>
                )}
              </Typography>
            )}
            <Grid
              container
              style={{ paddingTop: 10, justifyContent: "center" }}
            >
              <Grid item>
                <ButtonRounded
                  onClick={handleClose}
                  text={"Fechar"}
                ></ButtonRounded>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid
        container
        justify="center"
        style={{ paddingTop: "25px", textAlign: "center" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Transações de QRCode</Typography>
        </Grid>

        <Divider
          style={{
            background: "var(--color-orange-mid)",
            width: "20%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
            textAlign: "center",
          }}
        />
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ marginBottom: 10, alignItems: "center" }}
      >
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            id="initialDate"
            onChange={(ev) => setInitialDate(ev.target.value)}
            label="Data inicial"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            id="initialDate"
            onChange={(ev) => setFinalDate(ev.target.value)}
            label="Data final"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <b style={{ fontWeight: "bold" }}>Filtrar por:</b>
          <FormControlLabel
            style={{ paddingLeft: 10 }}
            control={
              <Checkbox
                variant="outlined"
                checked={input}
                color="primary"
                name="pagos"
                onChange={() => setInput(!input)}
              />
            }
            label="Pagos"
          />
          <FormControlLabel
            style={{ paddingLeft: 10 }}
            control={
              <Checkbox
                variant="outlined"
                checked={output}
                color="primary"
                name="naopagos"
                onChange={() => setOutput(!output)}
              />
            }
            label="Não pagos"
          />
         
        </Grid>

        <Grid item style={{justifyContent: 'flex-end'}}>
          <ButtonRounded onClick={search} text={"Buscar"}></ButtonRounded>
        </Grid>
      </Grid>

      <Divider
        style={{
          background: "var(--color-orange-mid)",
          width: "20%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
          textAlign: "center",
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Situação</TableCell>

              <TableCell align="right">Valor</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell  component="th" scope="row" align="left">{formatDate(row.transactionDate)}</TableCell>
                <TableCell align="left">
                  {row.status_name === "APPROVED"
                    ? "Pago"
                    : "Não pago"}
                </TableCell>

                <TableCell
                  align="right"
                  // style={
                  //   row.type === "C"
                  //     ? { color: "green" }
                  //     : row.type === "D"
                  //     ? { color: "red" }
                  //     : { textWeight: "bold" }
                  // }
                >
                  {
                    `R$ ${Number(row.totalAmount).toFixed(2)}`}
                </TableCell>
                <TableCell>
                  {(row.type === "C" || row.type === "D") && (
                    <IconButton
                      aria-label="Sair do sistema"
                      color="inherit"
                      onClick={() => handleClickOpen(row.transactionId)}
                    >
                      <Visibility />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "Todos", value: -1 }]}
                colSpan={3}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Linhas por página" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Linhas por página"}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default MyQrCode;
