import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  ExitToApp,
  AccountCircle,
  History,
  ChevronLeft,
  ChevronRight,
  AttachMoney,
  LocalAtm,
  Settings,
  Code
} from "@material-ui/icons";
import {
  AppBar,
  Drawer,
  Divider,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import UserContext from "../../contexts/User";
import MyAccounts from "./MyAccounts";
import MyTransactions from "./MyTransactions";
import Receive from "./Receive";
import Transfer from "./Transfer";
import SettingPage from "./Settings";
import ProfilePage from "./Profile";
import ConfirmationPopUpExit from "../../components/ConfirmationPopUpExit";
import MyQrCode from "./MyQrCode";
import ReceiveBilling from "./ReceiveBilling";
import MyPixTransactions from "./MyPixTransactions";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "var(--color-blue-dark)",
    marginLeft: drawerWidth,
    // width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    borderBottomRightRadius: "50px",
    borderTopRightRadius: "50px",
    backgroundColor: "var(--color-blue)",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    borderBottomRightRadius: "50px",
    borderTopRightRadius: "50px",
    backgroundColor: "var(--color-blue)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuHeader: {
    marginLeft: `${theme.spacing(7)}px`,
    transition: theme.transitions.create("marginLeft", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuHeader2: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create("marginLeft", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  empty: {},
}));


function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { user } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  const [modalShow, setModalShow] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (open) handleDrawerClose();
    else handleDrawerOpen();
  };

  async function exit() {
    localStorage.setItem("client", null);
    localStorage.setItem("token", null);
    history.replace(location.from || "/", {});
  }
  

  return (
    <div className={classes.root}>
      {modalShow && <ConfirmationPopUpExit    show={modalShow} onHide={() => setModalShow(false)} confirm={exit}/>}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          className={clsx(classes.empty, {
            [classes.menuHeader]: !open,
            [classes.menuHeader2]: open,
          })}
        >
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton> */}

          {!open && (
            <Typography variant="h6" noWrap>
              Olá, {user.name} ({user.taxId})!
            </Typography>
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton aria-label="Sair do sistema" color="inherit">
              <Visibility />
            </IconButton> */}
            <IconButton aria-label="Perfil" color="inherit"       onClick={() => history.push("/dashboard/profile")}>
              <AccountCircle />
            </IconButton>
            <IconButton aria-label="Sair do sistema" color="inherit" onClick={() => setModalShow(true)}>
              <ExitToApp />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Typography
            style={{ textAlign: "center", color: "white", fontWeight: "bold" }}
            variant="body1"
            noWrap
          >
            Olá, {user.name}!
          </Typography>
          <IconButton onClick={handleClick} style={{ color: "white" }}>
            {!open ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider
          style={{
            background: "var(--color-orange-mid)",
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <List>
          {/* <ListItem
            button
            style={{ color: "white" }}
            onClick={() => history.push("/dashboard/my-accounts")}
          >
            <ListItemIcon style={{ color: "white" }}>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary={"Minhas contas"} />
          </ListItem> */}
          <ListItem button style={{ color: "white" }} 
            onClick={() => history.push("/dashboard/my-transactions")}>
            <ListItemIcon style={{ color: "white" }}>
              <History />
            </ListItemIcon>
            <ListItemText primary={"Minhas transações"} />
          </ListItem>
          <ListItem button style={{ color: "white" }}
            onClick={() => history.push("/dashboard/transfer")}>
            <ListItemIcon style={{ color: "white" }}>
              <LocalAtm />
            </ListItemIcon>
            <ListItemText primary={"Transferência"} />
          </ListItem>
          <ListItem button style={{ color: "white" }} 
            onClick={() => history.push("/dashboard/receive")}>
            <ListItemIcon style={{ color: "white" }}>
              <AttachMoney />
            </ListItemIcon>
            <ListItemText primary={"Cobrar"} />
          </ListItem>
          <ListItem button style={{ color: "white" }} 
            onClick={() => history.push("/dashboard/my-qrcode")}>
            <ListItemIcon style={{ color: "white" }}>
              <Code />
            </ListItemIcon>
            <ListItemText primary={"Transações QRCode"} />
          </ListItem>
          {/* <ListItem button style={{ color: "white" }} 
            onClick={() => history.push("/dashboard/pix-billing")}>
            <ListItemIcon style={{ color: "white" }}>
              <Money />
            </ListItemIcon>
            <ListItemText primary={"PIX Cobrança"} />
          </ListItem> */}
          {/* <ListItem button style={{ color: "white" }} 
            onClick={() => history.push("/dashboard/pix-billing-transactions")}>
            <ListItemIcon style={{ color: "white" }}>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary={"Transações PIX"} />
          </ListItem> */}
          
        </List>
        <Divider
          style={{
            background: "var(--color-orange-mid)",
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <List><ListItem button style={{ color: "white" }}
             onClick={() => history.push("/dashboard/profile")}>
            <ListItemIcon style={{ color: "white" }}>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={"Perfil"} />
          </ListItem>
          <ListItem button style={{ color: "white" }}
             onClick={() => history.push("/dashboard/settings")}>
            <ListItemIcon style={{ color: "white" }}>
              <Settings />
            </ListItemIcon>
            <ListItemText primary={"Configurações"} />
          </ListItem>
          <ListItem button style={{ color: "white" }} 
             onClick={() => setModalShow(true)}>
            <ListItemIcon style={{ color: "white" }}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary={"Sair"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {location.pathname === '/dashboard/' && <MyTransactions />}
        {location.pathname === '/dashboard' && <MyTransactions />}
        {location.pathname === '/dashboard/my-transactions' && <MyTransactions />}
        {location.pathname === '/dashboard/my-accounts' && <MyAccounts />}
        {location.pathname === '/dashboard/receive' && <Receive />}
        {location.pathname === '/dashboard/pix-billing' && <ReceiveBilling />}
        {location.pathname === '/dashboard/pix-billing-transactions' && <MyPixTransactions />}
        {location.pathname === '/dashboard/my-qrcode' && <MyQrCode />}
        {location.pathname === '/dashboard/transfer' && <Transfer />}
        {location.pathname === '/dashboard/settings' && <SettingPage />}
        {location.pathname === '/dashboard/profile' && <ProfilePage />}
      </main>
    </div>
  );
}

export default Dashboard;
