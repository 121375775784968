import React, { useState, useContext } from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import ButtonRounded from "../../components/ButtonRounded";
import { Alert } from "@material-ui/lab";
import SnackbarContext from "../../contexts/Snackbar";
import ConfirmationPopUpTransfer from "../../components/ConfirmationPopUpTransfer";
import "../../assets/css/pages/transfer.css";
import requestAdapter from "../../api/request";
function Transfer() {
  const [value, setValue] = useState();
  const [alias, setAlias] = useState();
  const [loading, setLoading] = useState();
  const { setSnack } = useContext(SnackbarContext);

  const [error, setError] = useState({ hasError: false, errorMessage: "" });

  const [modalShow, setModalShow] = useState(false);
  const [dataForContirmation, setDataForConfirmation] = useState({});

  async function submit() {
    const user = await JSON.parse(localStorage.getItem('client'));
    if (!user || !user.bankAliasPix) {
      setError({
        hasError: true,
        errorMessage:
          "A administração ainda não cadastrou sua conta bancária. Contate a equipe MasterFin.",
      });
      return;
    }
    if (!value) {
      setError({ hasError: true, errorMessage: "Informe um valor válido!" });
      return;
    }

    setError({ hasError: false });
    setLoading(true);
    try {
      const res = await requestAdapter.post(`/transaction/cashout-two-steps`, {
        value,
        alias: user.bankAliasPix,
      });
      setModalShow(true);
      setDataForConfirmation(res.data.data);
    } catch (ex) {
      console.log(ex);
      setError({ hasError: true, errorMessage: ex.message });
      setLoading(false);
    }
    setLoading(false);
  }

  async function confirm() {
    const data = dataForContirmation;
    console.log(data);
    setModalShow(false);
    setError({ hasError: false });
    setLoading(true);
    try {
      const res = await requestAdapter.post(
        `/transaction/cashout-two-steps-second`,
        {
          value,
          alias,
          endToEndId: data.endToEndId,
          pspId: data.psp.id,
          taxIdentifier: data.aliasAccountHolder.taxIdentifier,
          accountDestination: data.accountDestination,
        }
      );

      if (res.status) {
        setValue("");
        setAlias("");
        setSnack({ open: true, message: "Transferência realizada!" });
      }
    } catch (ex) {
      console.log(ex);
      setError({ hasError: true, errorMessage: ex.message });
      setLoading(false);
    }
    setLoading(false);
  }

  const getPixInfo = () => {
    const user = JSON.parse(localStorage.getItem('client'));
    if (!user || !user.bankName || !user.bankAliasPix) return null;
    return 'Chave: ' + user.bankAliasPix + ` - Banco: ${user.bankName}`;
  };

  return (
    <div>
      {modalShow && (
        <ConfirmationPopUpTransfer
          show={modalShow}
          data={dataForContirmation}
          value={value}
          alias={alias}
          onHide={() => setModalShow(false)}
          confirm={confirm}
        />
      )}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{
          marginTop: "25px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "25px", borderWidth: 1, borderColor: "black" }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h5">Transferências</Typography>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: "25px" }}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                type="number"
                onChange={(ev) => setValue(ev.target.value)}
                variant="outlined"
                label="Valor desejado"
                required
                name="value"
                disabled={loading}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="text"
                onChange={(ev) => setAlias(ev.target.value)}
                variant="outlined"
                label="Chave PIX"
                required
                name="alias"
                disabled={loading}
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "25px" }}>
            <ButtonRounded
              disabled={loading}
              onClick={() => submit()}
              text={"Enviar"}
            ></ButtonRounded>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "25px" }}>
            <Typography variant="h6">Conta para transferência configurada</Typography>
            <Typography variant="body1">
              {getPixInfo() !== null && <span>{getPixInfo()}</span>}
              {getPixInfo() === null && (
                <span>
                  A administração ainda não cadastrou sua conta bancária. <br />
                  Contate a equipe MasterFin.
                </span>
              )}
            </Typography>
          </Grid>
          {error.hasError && (
            <Alert severity="warning" style={{ marginTop: "20px" }}>
              {error.errorMessage}
            </Alert>
          )}
          {loading && (
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <CircularProgress></CircularProgress>
            </Grid>
          )}
        </div>
      </Grid>
    </div>
  );
}

export default Transfer;
