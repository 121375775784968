import React, { useContext } from "react";
import { Grid, TextField } from "@material-ui/core";
import ValidationsForm from "../../contexts/ValidationsForm";
import useErrors from "../../hooks/useErrors";
import ButtonRounded from "../../components/ButtonRounded";
function FormRegisterAccessData({ next, back, values, setValues }) {
  const validations = useContext(ValidationsForm);
  const [errors, checkFields, canSubmit] = useErrors(validations);
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      login: { ...values.login, [prop]: event.target.value },
    });
  };

  const submit = () => {
    if (canSubmit()) next();
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        submit();
      }}
      className="formContent"
    >
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            type="text"
            value={values.login.email}
            onChange={handleChange("email")}
            variant="outlined"
            label="Email"
            name="email"
            disabled={values.loading}
            error={!errors.email.valid}
            helperText={errors.email.text}
            onBlur={checkFields}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="password"
            value={values.login.password}
            onChange={handleChange("password")}
            variant="outlined"
            name="password"
            label="Senha"
            disabled={values.loading}
            required
            error={!errors.password.valid}
            helperText={errors.password.text}
            onBlur={checkFields}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="password"
            value={values.login.password2}
            onChange={handleChange("password2")}
            variant="outlined"
            name="password2"
            label="Confirmar senha"
            disabled={values.loading}
            required
            error={!errors.password2.valid}
            helperText={errors.password2.text}
            onBlur={(ev) => checkFields(ev, values.login.password)}
          />
        </Grid>
   

        <Grid item xs={12} md={12}>
          <ButtonRounded
            disabled={values.loading}
            onClick={back}
            style={{ marginTop: "20px" }}
            text={"Voltar"}
            shadowLight={true}
            color={"var(--color-gray-dark)"}
          ></ButtonRounded>
          <ButtonRounded
            disabled={values.loading}
            type="submit"
            style={{ marginTop: "20px", marginLeft: "10px" }}
            text={"Enviar"}
          ></ButtonRounded>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormRegisterAccessData;
