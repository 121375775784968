import React, { useState, useContext } from "react";
import {  StepLabel, Stepper, Step } from "@material-ui/core";
import DoubleDiv from "../../components/DoubleDiv";
import {
  validateCPF,
  validatePassword,
  validateGeneric,
  validateEmail,
  validatePassword2,
  validateCNPJ,
  validateNumber,
  validateCEP,
  validateDate,
  validateState,
} from "../../utils/validations";

import requestAdapter from "../../api/request";
import ValidationsForm from "../../contexts/ValidationsForm";
import "../../assets/css/pages/register.css";
import FormRegisterCompanyData from "../../components/dashboard/FormRegisterCompanyData";
import FormRegisterPersonalData from "../../components/dashboard/FormRegisterPersonalData";
import FormRegisterAccessData from "../../components/dashboard/FormRegisterAccessData";
import { useHistory } from "react-router-dom";

import SnackbarContext from "../../contexts/Snackbar";
import { justNumbers } from "../../utils/utils";

function Register() {
  const { setSnack } = useContext(SnackbarContext);
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);

  const [formData, setFormData] = useState({
    loading: false,
    hasError: false,
    errorMessage: "",
    company: {
      name: "",
      companyName: "",
      date: "",
      number: "",
      cnpj: "",
      email: "",
      address: {
        cep: "",
        city: "",
        number: "",
        street: "",
        complement: "",
        district: "",
        state: "",
        country: "",
      },

      documents: [],
    },
    personal: {
      name: "",
      mother: "",
      email: "",
      date: "",
      number: "",
      cpf: "",
      address: {
        cep: "",
        city: "",
        number: "",
        street: "",
        complement: "",
        district: "",
        state: "",
        country: "",
      },
      picture: null,
      cnh: null,
      identity_front: null,
      identity_back: null,
    },
    login: {
      email: "",
      password: "",
      password2: "",
    }
  });

  const forms = [
    <FormRegisterCompanyData
      next={next}
      values={formData}
      setValues={setFormData}
    />,
    <FormRegisterPersonalData
      next={next}
      back={previous}
      values={formData}
      setValues={setFormData}
    />,
    <FormRegisterAccessData
      next={next}
      back={previous}
      values={formData}
      setValues={setFormData}
    />,
  ];

  function next() {
    if (currentStep === forms.length - 1) {
      register();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  function previous() {
    setCurrentStep(currentStep - 1);
  }

  async function register() {
    formData.loading = true;
    formData.hasError = false;
    setFormData({ ...formData });
    
    try {
      const json = {
        user: {
          email: formData.login.email,
          password: formData.login.password,
        },
        address: formData.company.address,
        client: {
          name: formData.company.name,
          email: formData.company.email,
          phoneNumber: justNumbers(formData.company.number, 1),
          taxId: justNumbers(formData.company.cnpj, 1),
        },
        representative: {
          address: formData.personal.address,
          name: formData.personal.name,
          mother: formData.personal.mother,
          birthday: formData.personal.date,
          phoneNumber: justNumbers(formData.personal.number, 1),
          email: formData.personal.email,
          taxId: justNumbers(formData.personal.cpf, 1),
        },
        corporateDetail: {
          establishmentDate: formData.company.date,
          companyName: formData.company.companyName,
        },
        documents: formData.company.documents,
        documentsRepresentative: formData.personal.picture ? [formData.personal.picture]: []
      };

      if(formData.personal.cnh) {
        json.documentsRepresentative.push(formData.personal.cnh);
      } else {
        if(formData.personal.identity_front) {
          json.documentsRepresentative.push(formData.personal.identity_front);
        }
  
        if(formData.personal.identity_back) {
          json.documentsRepresentative.push(formData.personal.identity_back);
        }
      }


      json.address.number = justNumbers(json.address.number);
      json.address.cep = justNumbers(json.address.cep);
      json.representative.address.number = justNumbers(
        json.representative.address.number
      );
      json.representative.address.cep = justNumbers(
        json.representative.address.cep
      );
      console.log(json);
      await requestAdapter.post("/register", json);
      userCreated();
    } catch (error) {
      formData.errorMessage = error.message;
      formData.hasError = true;
      setFormData({ ...formData });
    }

    formData.loading = false;
    setFormData({ ...formData });
  }

  const userCreated = () => {
    setSnack({ open: true, message: "Cadastro realizado com sucesso!" });
    history.replace("/", {});
  };

  return (
    <div className="mainRegister">
      <div className="contentRegister">
        <div>
          {/* <Container
          space={4}
          maxWidth="sm"
          
          style={{
            "textAlign": "center",
            position: "relative",
            height: "100%",
            marginTop: "220px",
            maxWidth: "80%",
          }}
        > */}
          <DoubleDiv values={formData} top={"0px"} transform={"translate(-50%)"}>
            <Stepper activeStep={currentStep}>
              <Step>
                <StepLabel>Dados da empresa</StepLabel>
              </Step>
              <Step>
                <StepLabel>Dados do responsável da empresa</StepLabel>
              </Step>
              <Step>
                <StepLabel>Login e senha</StepLabel>
              </Step>
            </Stepper>
            <ValidationsForm.Provider
              value={{
                cpf: validateCPF,
                cnpj: validateCNPJ,
                password: validatePassword,
                name: validateGeneric,
                companyName: validateGeneric,
                date: validateDate,
                email: validateEmail,
                password2: validatePassword2,
                number: validateNumber,
                cep: validateCEP,
                city: validateGeneric,
                street: validateGeneric,
                district: validateGeneric,
                state: validateState,
                numberStreet: validateGeneric,
              }}
            >
              {forms[currentStep]}
            </ValidationsForm.Provider>
          </DoubleDiv>
        </div>
      </div>
    </div>
  );
}

export default Register;
