import React, { useEffect, useState, useContext } from "react";

import requestAdapter from "../../api/request";
import UserContext from "../../contexts/User";
import SnackbarContext from "../../contexts/Snackbar";
import ButtonRounded from "../../components/ButtonRounded";
import InputMask from "react-input-mask";
import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import getAddresByCEP from "../../api/viacep";


function ReceiveBilling() {
  const { user } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [pixAlias, setPixAlias] = useState(null);
  const [lookedPixAlias, setLookedPixAlias] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [tax, setTax] = useState(null);
  const [error, setError] = useState({ hasError: false, errorMessage: "" });
  const [loadingQr, setLoadingQr] = useState(false);
  const [qrCode, setQrCode] = useState();

  const [dueDate, setDueDate] = useState(null);
  const [name, setName] = useState(null);
  const [street, setStreet] = useState(null);
  const [uf, setUf] = useState(null);
  const [cep, setCep] = useState(null);
  const [city, setCity] = useState(null);
  const [docNumber, setDocNumber] = useState(null);
  const [juros, setJuros] = useState(null)
  const [file] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get(`/pix/${user.accountId}`);
        if (res.status === 200) {
          if (res.data && res.data.aliases && res.data.aliases.length) {
            setPixAlias(res.data.aliases[0].name);
          }
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
      setLookedPixAlias(true);
    };
    getData();
  });

  async function generatePixAlias() {
    setLoading(true);
    try {
      const res = await requestAdapter.post(`/pix/`, {
        externalIdentifier: user.externalIdentifier,
      });
      if (res.status) {
        setSnack({
          message: "Solicitação de criação de chave realizada com sucesso",
          open: true,
        });
      }
    } catch (ex) {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
    setLoading(false);
  }

  async function getNewPayment() {
    setError({ hasError: false, errorMessage: "Informe um valor válido!" });
    if (!value) {
      setError({ hasError: true, errorMessage: "Informe um valor válido!" });
      return;
    }
    if (!dueDate) {
      setError({
        hasError: true,
        errorMessage: "Informe a data de vencimento!",
      });
      return;
    }

    if (!name) {
      setError({ hasError: true, errorMessage: "Informe o nome do pagador!" });
      return;
    }
    if (!tax) {
      setError({
        hasError: true,
        errorMessage: "Informe o CPF/CNPJ do pagador",
      });
      return;
    }
    if (!cep) {
      setError({ hasError: true, errorMessage: "Informe o CEP do pagador!" });
      return;
    }
    if (!uf) {
      setError({
        hasError: true,
        errorMessage: "Informe o estado do pagador!",
      });
      return;
    }
    if (!city) {
      setError({
        hasError: true,
        errorMessage: "Informe a cidade do pagador!",
      });
      return;
    }
    if (!street) {
      setError({
        hasError: true,
        errorMessage: "Informe o endereço do pagador!",
      });
      return;
    }

    setQrCode(null);
    setLoadingQr(true);
    try {
      const res = await requestAdapter.post(`/transaction-billing`, {
        value,
        dueDate,
        cpfCnpj: tax,
        name,
        street,
        city,
        uf,
        cep: cep.split("-").join(""),
        tax: juros,
        docNumber,
      });
      setError({ hasError: false, errorMessage: "Informe um valor válido!" });
      if (res.status) {
        setQrCode(res.data.data);
        const arr = new Uint8Array(res.data.data.pdf.data,)
        const blob = new Blob([arr], {type:"application/pdf"});
        // const url = window.URL.createObjectURL(blob);
        // window.open(url,"_blank");

        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "cobranca_" + res.data.data.transaction_id + ".pdf";
        link.click();
        // document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      }
    } catch (ex) {
      console.log(ex);
      setError({ hasError: true, errorMessage: ex.message });
    }
    setLoadingQr(false);
  }

  function copy() {
    navigator.clipboard.writeText(pixAlias);
    setSnack({
      message: "Chave PIX copiada para área de transferência",
      open: true,
      severity: "info",
    });
  }

  const getAddres = async () => {
    setLoadingQr(true);
    try {
      const x = await getAddresByCEP(cep);
      if (!x.data.erro) {
        if (x.data.localidade) setCity(x.data.localidade);
        if (x.data.logradouro) setStreet(x.data.logradouro);
        if (x.data.uf) setUf(x.data.uf);
      }
    } catch {}
    setLoadingQr(false);
  };

  // const download = async() => {
    // console.log(document.getElementById('pdf').innerHTML)
    // // var doc = new jsPDF('l');
    // // doc.html(`<html><head><title>COBRANÇA</title></head><body>` + document.getElementById('pdf').innerHTML + `</body></html>`);
    // // setTimeout(function(){
    // //   doc.save('cobranca_masterfin.pdf');
    // //   },2000);

    // let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

    // mywindow.document.write(`<html><head><title>Cobrança MASTERFIN</title>`);
    // mywindow.document.write('</head><body >');
    // mywindow.document.write(document.getElementById('pdf').innerHTML);
    // mywindow.document.write('</body></html>');
  
    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/
  
    // mywindow.print();
    // mywindow.close();
  // }

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          marginTop: "25px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">PIX Cobrança</Typography>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "15%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Typography variant="body1">
              Solicitar uma nova cobrança PIX
            </Typography>
          </Grid>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                type="number"
                onChange={(ev) => setValue(ev.target.value)}
                variant="outlined"
                size="small"
                label="Valor desejado"
                required
                name="value"
                value={value}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                type="number"
                onChange={(ev) => setJuros(ev.target.value)}
                variant="outlined"
                size="small"
                label="Juros"
                name="juros"
                value={juros}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="dueDate"
                onChange={(ev) => setDueDate(ev.target.value)}
                label="Data de vencimento"
                type="date"
                value={dueDate}
                disabled={loadingQr}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                onChange={(ev) => setName(ev.target.value)}
                variant="outlined"
                size="small"
                label="Nome do pagador"
                required
                name="name"
                value={name}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                onChange={(ev) => setTax(ev.target.value)}
                variant="outlined"
                size="small"
                label="CPF/CNPJ"
                placeholder={"Somente números"}
                required
                name="tax"
                value={tax}
                disabled={loadingQr}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} justify="center" style={{ marginTop: 5 }}>
          <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                onChange={(ev) => setDocNumber(ev.target.value)}
                variant="outlined"
                size="small"
                label="Núm. do doc."
                name="docNumber"
                value={docNumber}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <InputMask
                mask="99999-999"
                value={cep}
                name="cep"
                onChange={(ev) => setCep(ev.target.value)}
                onBlur={(ev) => {
                  getAddres();
                }}
                disabled={loadingQr}
              >
                {() => (
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="CEP"
                    name="cep"
                    size="small"
                    disabled={loadingQr}
                    required
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                onChange={(ev) => setStreet(ev.target.value)}
                variant="outlined"
                size="small"
                label="Endereço"
                required
                name="street"
                value={street}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                onChange={(ev) => setUf(ev.target.value)}
                variant="outlined"
                size="small"
                label="Estado"
                required
                name="uf"
                value={uf}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <TextField
                fullWidth
                onChange={(ev) => setCity(ev.target.value)}
                variant="outlined"
                size="small"
                label="Cidade"
                required
                name="city"
                value={city}
                disabled={loadingQr}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ButtonRounded
                disabled={loadingQr}
                onClick={() => getNewPayment()}
                text={"Gerar Cobrança"}
              ></ButtonRounded>
            </Grid>
          </Grid>
          <Divider
            spacing={4}
            style={{
              background: "var(--color-orange-mid)",
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 10,
            }}
          />
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              {!lookedPixAlias && <div>Carregando suas informações...</div>}
              {lookedPixAlias && !pixAlias && (
                <div>
                  Você não possui nenhuma chave PIX cadastrada!
                  <br />
                  <ButtonRounded
                    disabled={loading}
                    type="submit"
                    text={"Gerar nova chave"}
                    onClick={() => generatePixAlias()}
                  ></ButtonRounded>
                </div>
              )}
              {lookedPixAlias && pixAlias && (
                <div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      Sua chave pix é:
                    </Grid>
                    <Grid item xs={12}>
                      <span style={{ fontWeight: "bold" }}>{pixAlias}</span>{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          textDecorationLine: "underline",
                          color: "blue",
                        }}
                        onClick={() => copy()}
                      >
                        Copiar
                      </span>
                    </Grid>
                  </Grid>

                  <br />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        {error.hasError && (
          <Alert severity="warning" style={{ marginTop: "20px" }}>
            {error.errorMessage}
          </Alert>
        )}
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          style={{ marginTop: "20px" }}
        >
          {loadingQr && (
            <Grid item xs={3}>
              <CircularProgress></CircularProgress>
            </Grid>
          )}
          {qrCode && file && (
           <>Cobrança gerada com sucesso. <br/>Você pode utilizar o código abaixo para pagar.<br/><br/></>
          )}
          {qrCode && (
            <Grid item xs={12}>
              {qrCode.textContent}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ReceiveBilling;
