import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import UserContext from "../../contexts/User";

import requestAdapter from "../../api/request";

function ProtectedRoute({ children, ...rest }) {
  const jwtToken = localStorage.getItem("token");
  const [isLoggedIn, setLogged] = useState(jwtToken ? true : false);
  const [downloaded, setDownloaded] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await requestAdapter.get("/fullProfile");
        if(res.status === 200 && res.data.client) {
          localStorage.setItem("client", JSON.stringify(res.data.client));
          setUser(res.data.client);
        }
        setDownloaded(true);
        setLogged(res.status === 200);
      } catch {
        setLogged(false);
        setDownloaded(true);
      }
    }
    getData();
  }, []);

  return (
    downloaded && 
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          <UserContext.Provider value={{user}}>{children}</UserContext.Provider>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default ProtectedRoute;
