import logger from "../utils/logger";
const axios = require("axios");


const BASE_API = `https://viacep.com.br/ws/`;
const getAddresByCEP = (cep) => {
  try {
    const URL = `${BASE_API}/${cep}/json`;
    const options = {
      method: "GET",
      url: URL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(options);
  } catch (ex) {
    logger.error(ex);
    throw new Error(ex.message || ex.statusText);
  }
};

export default getAddresByCEP;
