import React, { useContext, useState } from "react";

import { Grid, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import ValidationsForm from "../../contexts/ValidationsForm";
import useErrors from "../../hooks/useErrors";
import getAddresByCEP from "../../api/viacep";

function Address({ address, setValues }) {
  const validations = useContext(ValidationsForm);
  const [errors, checkFields] = useErrors(validations);

  const [loading, setLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues(prop, event.target.value);
  };

  const getAddres = async () => {
    setLoading(true);
    try {
      const x = await getAddresByCEP(address.cep);
      if (!x.data.erro) {
        const add = {};
        if (x.data.bairro) add.district = x.data.bairro;
        if (x.data.complement) add.complement = x.data.complemento;
        if (x.data.localidade) add.city = x.data.localidade;
        if (x.data.logradouro) add.street = x.data.logradouro;
        if (x.data.uf) add.state = x.data.uf;
        add.country = "BRA";
        for (const x in add) {
          setValues(x, add[x]);
        }
      }
      console.log(x);
    } catch {}
    setLoading(false);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputMask
          mask="99999-999"
          value={address.cep}
          name="cep"
          onChange={handleChange("cep")}
          onBlur={(ev) => {
            checkFields(ev);
            getAddres();
          }}
          disabled={loading}
        >
          {() => (
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              label="CEP"
              name="cep"
              required
              error={!errors.cep.valid}
              helperText={errors.cep.text}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="text"
          value={address.city}
          onChange={handleChange("city")}
          variant="outlined"
          label="Cidade"
          name="city"
          required
          disabled={loading}
          onBlur={checkFields}
          error={!errors.city.valid}
          helperText={errors.city.text}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          type="text"
          value={address.street}
          onChange={handleChange("street")}
          variant="outlined"
          label="Logradouro"
          name="street"
          disabled={loading}
          required
          onBlur={checkFields}
          error={!errors.street.valid}
          helperText={errors.street.text}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="number"
          value={address.number}
          onChange={handleChange("number")}
          variant="outlined"
          label="Número"
          name="numberStreet"
          disabled={loading}
          required
          onBlur={checkFields}
          error={!errors.numberStreet.valid}
          helperText={errors.numberStreet.text}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="text"
          value={address.complement}
          onChange={handleChange("complement")}
          variant="outlined"
          label="Complemento"
          name="number"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="text"
          value={address.district}
          onChange={handleChange("district")}
          variant="outlined"
          label="Bairro"
          name="district"
          disabled={loading}
          required
          onBlur={checkFields}
          error={!errors.district.valid}
          helperText={errors.district.text}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="text"
          value={address.state}
          onChange={handleChange("state")}
          variant="outlined"
          label="Estado"
          name="state"
          disabled={loading}
          required
          onBlur={checkFields}
          error={!errors.state.valid}
          helperText={errors.state.text}
        />
      </Grid>
    </>
  );
}

export default Address;
